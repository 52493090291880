.fundoLogin{
    width: 100vw;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.contentLogin{
    width: 40vw;
    height: max-content;
    border-radius: 10px;
    padding: 5rem;
}
.contentLogin h2, label{
    color: white;
}
.btn-entrar{
    float: right;
}
.btn-entrar:hover{
    float: right;
}